import React from 'react';
import "../styles/Footer.scss";
import {Path} from "../../navigation/MainNavigator";
import {useNavigate} from "react-router-dom";

export const enum TermsIdentifier {
	//개인정보 처리방침
	PrivacyPolicy = "PRIVACY_POLICY",
	//이용약관
	TermsOfService = "TERMS_OF_SERVICE",
}

const Footer = () => {
	const navigate = useNavigate()
	return (
		<div id={"Footer"}>
			<div className={"footer-content gap"}>
				<div className={"footer-link"}
						 onClick={() => navigate(Path.PrivacyPolicy, {state: {type: TermsIdentifier.PrivacyPolicy}})}>
					개인정보처리방침
				</div>
				<div className={"footer-link"}
						 onClick={() => navigate(Path.Terms, {state: {type: TermsIdentifier.TermsOfService}})}>
					이용약관
				</div>
			</div>
			<div className={"footer-content gap"}>
				<div className={"footer-text"}>
					주식회사 이큐비알 홀딩스
					|
					주소: 서울특별시 강남구 봉은사로 411, 6층
					|
					사업자번호: 848-88-02126
					|
					통신판매업신고번호: 제2024-서울강남-05032호
					|
					대표이사: 이현기, 한진수
					|
					TEL: 02-553-2818
				</div>
			</div>
		</div>
	);
};

export default Footer;
