import React from 'react';
import "../styles/Home.scss"
import memble from "../assets/images/memble_mobile.png";

const Home = () => {
	return (
		<div id={"Home"}>
			<div className={"home-content"}>
				<div className="home-text-wrapper">
					<div className={"home-slogan"}>
						Monetize yourself,
					</div>
					<div className={"home-logo"}>
						Memble
					</div>
					<div className={"home-description"}>
						Memble을 통해 손쉽게, 나만의 멤버십 비즈니스를 운영하세요
					</div>
				</div>
				<img src={memble} className={"home-image"}/>
			</div>
		</div>
	);
};

export default Home;
