import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "../view/pages/Home";
import Layout from "../view/components/Layout";
import Terms from "../view/pages/Terms";

export const enum Path {
	Home = '/',
	Terms = '/terms',
	PrivacyPolicy = '/privacy-policy',
}

const MainNavigator = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<Layout/>}>
					<Route index element={<Home/>}/>
					<Route path='terms/*' element={<Terms/>}/>
					<Route path='privacy-policy/*' element={<Terms/>}/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default MainNavigator;
