import './App.scss';
import "./view/styles/_reset.scss"
import MainNavigator from "./navigation/MainNavigator";

function App() {
	return (
		<div id="App">
			<MainNavigator/>
		</div>
	);
}

export default App;
