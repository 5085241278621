import React from 'react';
import "../styles/Header.scss"
import logo from "../assets/images/logo.png"
import {useNavigate} from "react-router-dom";
import {Path} from "../../navigation/MainNavigator";

const Header = () => {
	const navigate = useNavigate();
	return (
		<div id={"Header"}>
			<img src={logo} alt="logo" className={"logo"} onClick={() => navigate(Path.Home)}/>
		</div>
	);
};

export default Header;
