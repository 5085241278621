import React from 'react';
import "../styles/Layout.scss"
import Header from "./Header";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";

const Layout = () => {
	return (
		<div id={"Layout"}>
			<Header/>
			<Outlet/>
			<Footer/>
		</div>
	);
};

export default Layout;
