import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useLocation} from "react-router-dom";
import {TermsIdentifier} from "../components/Footer";
import "../styles/Terms.scss";

const Terms = () => {
	const location = useLocation();
	const {state} = location;
	const {type} = state || {type: TermsIdentifier.TermsOfService};

	const [term, setTerm] = useState({terms_id: 0, content_uri: ""});
	const getTermsUri = async () => {
		try {
			const termsResult = await axios.get(process.env.REACT_APP_MEMBLE_END_POINT + "/api/v1/terms", {
				params: {
					customIdentifier: type
				}
			})
			const {data} = termsResult
			const latestTermsResult = await axios.get(process.env.REACT_APP_MEMBLE_END_POINT + "/api/v1/terms/versions", {
				params: {
					termsId: data[0].terms_id,
					isLatest: true
				}
			})
			const {data: [versionData]} = latestTermsResult || {data: [{}]}
			setTerm(versionData)
		} catch (e) {
			console.error(e)
		}
	}
	useEffect(() => {
		getTermsUri()
	}, [type]);

	return (
		<div id={"Terms"}>
			{term && term.content_uri &&
				<iframe className={"terms-detail"} title={term.terms_id.toString()} src={term.content_uri}
								allowTransparency={true}/>
			}
		</div>
	);
};

export default Terms;
